import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import FeatureCard from "../../components/cards/FeatureCard"
import Toggle from "../../components/controls/Toggle"
import Layout from "../../components/layout/Layout"
import ProcessCard from "../../components/cards/ProcessCard"
import CanvasToolbar from "../../components/canvas/CanvasToolbar"
import Canvas from "../../components/canvas/Canvas"


import { useState } from "react"



export default function Pages( {data}) {

const [isTooltip, toggleTooltip] = useState(false);

return (
  <>
  <CanvasLayout>
  {isLoggedIn() ? (

<div>

  <CanvasToolbar>
    <div><a href={data.airtable.data.ProjectPath}><span className="">&lt; Back to {data.airtable.data.ProjectTxt}</span></a>s</div>
  </CanvasToolbar>

 <Canvas title={data.airtable.data.Name} introduction={data.airtable.data.Introduction}>  
    
      <div className="flex flex-col lg:flex-row m-2">
        
        {data.airtable.data.Module_BusinessProcessesL1.map((ProcessL1Array) => (
          <div className="border-r-8 border-white h-36 z-1">
            {/* L2 Heading */}
            <ProcessCard title={ProcessL1Array.data.Name} level="level1"/>
            
            <div>
              <div  className="flex flex-col lg:flex-row py-2 space-x-2">
                {ProcessL1Array.data.Module_BusinessProcessesL2.map((ProcessL2Array) => (
                  <div>
                  
                  <div className="h-24 overflow-auto z-1">
                      {ProcessL2Array.data.Metrics ?
                        <>
                        
                        {ProcessL2Array.data.Metrics.map((MetricsArray) => (
                          <div className=" flex flex-row space-x-8 p-2">
                            <div className="w-2/3">
                              <div className="flex flex-row items-center  z-1">
                     
                                <Type type="h5" nopadding="yes">{MetricsArray.data.Name}</Type>
                                <button onClick={() => toggleTooltip(!isTooltip)} className="w-4 h-4 p-1 ml-4 border hover:border-black hover:text-black border-slate rounded-full text-slate text-xs flex text-center items-center">?</button>
                                <div className={`${ isTooltip ? `block` : `hidden` } absolute w-80 left top-20 z-10 bg-slate rounded-xl text-black flex flex-row`}>
                                  <div className="text-xs p-4">{MetricsArray.data.Description}</div>
                                  <div className="text-base py-2 px-4 font-light top right"><button  onClick={() => toggleTooltip(!isTooltip)} className="text-black/75 hover:text-black">x</button></div>
                                </div>
 
 

                         
                              </div>
                            </div>
                            <div className="grow">
                             <div className="rounded-full bg-black text-white text-center px-2 mx-auto">
                                <span className="text-xl font-bold">{MetricsArray.data.Value}</span> <span className="text-md font-medium">{MetricsArray.data.Symbol}</span>
                              </div>
                            </div>
                          </div>
                          ))}
                      </>
                        :
                        undefined
                      }
                  </div>
          

        
                  <div className="h-36">
                    <ProcessCard title={ProcessL2Array.data.Name} level="level2"/>
            
                    <div className="pt-2">
                      {ProcessL2Array.data.ProcessesL3.map((ProcessL3Array) => (
                        <div className="min-w-[320px]"><ProcessCard level="level3" title={ProcessL3Array.data.Name}/></div>
                      ))}
                    </div>
                  </div>
                  </div>    
                ))}
              </div>
            </div>
          </div>
          ))}
      </div> 
  </Canvas>

</div>
   
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}
  </CanvasLayout>
    </>

  )
}

export const Head = () => <title>Journey</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "ValueStreams" },
    data: { Path: { eq: $Path } }) {
      data {
        Name
        ProjectTxt
        ProjectPath
        Module_BusinessProcessesL1
        {
          data
          {
            Name
            Path
            Module_BusinessProcessesL2
            {
              data
              {
                Name
                Metrics {data {
                  Name
                  Value
                  Symbol
                  Description
                }}
                Path
                ProcessesL3
                {
                  data
                  {
                    Name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
